export const environment = {
  production: true,
  preview: false,
  siteURL: 'https://www.ahome.com.tw',
  firebase: {
    apiKey: 'AIzaSyClkwX7QU3RjUl3VDGj74_Fiq4xl0gDqoU',
    authDomain: 'orange-house-20240804.firebaseapp.com',
    projectId: 'orange-house-20240804',
    storageBucket: 'orange-house-20240804.appspot.com',
    messagingSenderId: '533458037828',
    appId: '1:533458037828:web:1eeb9cfebd7fea3b86e011',
  },
  geocodingKey: 'AIzaSyChtFKZtsJD9SddgrHC798277jCPXghbkE',
  fbPageId: '100083525340824',
  fbAppId: '101115609238001',
  awoo: {
    url: 'https://api.awoo.org/nununi',
    id: 2204912200,
    token: 'cfe8206976cd4d9ccd4314c5f4aebbb3a1e6dbe0',
  },
};
