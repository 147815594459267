import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { catchError, map, of, shareReplay } from 'rxjs';

import { urlJoin } from '@alan-apps/utils';
import { BlockViewService } from '@nghedgehog/angular-ui';

import { environment } from '../../../environments/environment';

interface ClassifyProductTypeParams {
  productType: string;
  sort: string;
}

interface ClassifyParams {
  productIds: string[];
  sort: 'default';
}

export interface Tag {
  text: string;
  link: string;
  fullLink: string;
  products: TagProduct[];
}
interface TagProduct {
  product_id: string;
  product_name: string;
  product_type: string;
  url: string;
  product_image_url: string;
  product_brand: string;
  product_price: string;
  product_price_currency: string;
}
export interface AwooResponse<T> {
  errcode: number;
  errmsg: string;
  requestId: string;
  result: T;
}

export interface Page {
  pageInfo: PageInfo;
  categoriesTags: any[];
  suggestionTags: SuggestionTag[];
  products: Product[];
  productsTotal: number;
  tagsFilter: string[];
}

interface Product {
  productId: string;
  productName: string;
  url: string;
  productImageUrl: string;
  productBrand: string;
  productPrice: number;
  productPriceCurrency: string;
  productType: string;
  productDescription: string;
  productAvailability: string;
}

export interface SuggestionTag {
  text: string;
  link: string;
  fullLink: string;
}

interface PageInfo {
  title: string;
  description: string;
  h1: string;
  canonical: string;
  openGraph: OpenGraph;
  schema: Schema;
  intro: string;
}

interface Schema {
  breadcrumbs: string;
}

export interface Breadcrumbs {
  '@context': string;
  '@type': string;
  itemListElement: ItemListElement[];
}

interface ItemListElement {
  '@type': string;
  position: number;
  item: Item;
}

interface Item {
  '@id': string;
  name: string;
}

interface OpenGraph {
  title: string;
  description: string;
  siteName: string;
  url: string;
  image: string;
  type: string;
}

type TagResponse = {
  tags: Tag[];
  products?: ProductsResponse;
};

export interface ProductsResponse {
  'directions:v': DirectionsV;
  'directions:h': DirectionsV;
}

interface DirectionsV {
  products: Product[];
}

interface Product {
  productId: string;
  url: string;
  productImageUrl: string;
  productName: string;
  productPrice: number;
  productPriceCurrency: string;
  productType: string;
  productBrand: string;
}

const processTagsFullLink = map((res: AwooResponse<TagResponse>) =>
  res.result.tags.map((tag) => ({
    ...tag,
    fullLink: `/tags/${decodeURIComponent(tag.link)}`,
  })),
);
@Injectable({
  providedIn: 'root',
})
export class AwooService {
  http = inject(HttpClient);
  block = inject(BlockViewService);

  private getBaseUrl(version: string, endpoint: string) {
    return urlJoin(
      environment.awoo.url,
      version,
      `${environment.awoo.id}`,
      endpoint,
    );
  }

  popularTags$ = this.getPopularTags('products').pipe(
    processTagsFullLink,
    catchError((err) => {
      console.error('🐞 ~ err:', err);
      return of<Tag[]>([]);
    }),
    shareReplay(1),
  );

  getPopularTags(select?: 'products') {
    return this.http.get<AwooResponse<TagResponse>>(
      this.getBaseUrl('v1', 'populartags'),
      select
        ? {
            params: { select },
          }
        : undefined,
    );
  }

  postClassifyProductType(body: ClassifyProductTypeParams) {
    return this.http.post(
      this.getBaseUrl('v2.3', 'products/classifyProductType'),
      body,
    );
  }

  postClassify(body: ClassifyParams) {
    return this.http
      .post<
        AwooResponse<TagResponse>
      >(this.getBaseUrl('v2.3', 'products/classify'), body)
      .pipe(processTagsFullLink);
  }

  getProductTags(id: number | string) {
    const obs = this.http.get<AwooResponse<TagResponse>>(
      this.getBaseUrl('v3', `products/${id}/tags`),
      {
        params: {
          directions: 'v,h',
          select: 'tags',
          limit: 5,
        },
      },
    );

    return obs.pipe(processTagsFullLink);
  }

  getProductTagsProducts(id: number | string) {
    const obs = this.http.get<AwooResponse<TagResponse>>(
      this.getBaseUrl('v3', `products/${id}/tags`),
      {
        params: {
          directions: 'v,h',
          select: 'products',
          limit: 5,
        },
      },
    );

    return obs.pipe(map((res) => res.result.products));
  }

  getPage(tags: string) {
    return this.http
      .get<AwooResponse<Page>>(this.getBaseUrl('v1.3', `page`), {
        params: {
          tags,
          page: '1',
          // TODO: use 500 temporary, should implement pagination
          limit: 500,
        },
      })
      .pipe(
        map((res) => {
          const result = res.result;
          return {
            ...result,
            suggestionTags: result.suggestionTags.map((tag) => ({
              ...tag,
              fullLink: `/tags/${decodeURIComponent(tag.link)}`,
            })),
          };
        }),
      );
  }
}
